/**
 * Возвращает процент
 * @param count количество
 * @param total общее количество
 * @returns процент
 */
export default function getPercentage(
  count: number,
  total: number,
): number {
  return Math.round((count / total) * 100);
}
