
import { SurveyStageKind, SurveyStageType } from '@/constants/surveyStage';
import Vue from 'vue';

const DEFAULT_PROGRESS = {
  count: 0,
  percent: 0,
  total: 0,
};

export default Vue.extend({
  name: 'StageCard',

  components: {},
  props: {
    stage: {
      type: Object,
      default: () => ({}),
    },
    selected: {
      type: Boolean,
      default: false,
    },
    progress: {
      type: Object,
      default: () => ({ ...DEFAULT_PROGRESS }),
    },
  },
  data() {
    return {
      titles: {
        [SurveyStageKind.CHOOSING_EXPERTS]: this.$t('surveyCompletionStatistics.stageCard.title.choosingExperts'),
        [SurveyStageKind.PASSAGE]: this.$t('surveyCompletionStatistics.stageCard.title.passage'),
      },
    };
  },
  computed: {
    title(): string {
      return this.titles[this.stage.kind as SurveyStageKind] as string;
    },
    date(): string {
      const {
        dateStart,
        dateEnd,
      } = this.stage;
      const startDate = this.$dayjs.utc(dateStart).format('DD');
      const endDate = this.$dayjs.utc(dateEnd).format('DD MMMM YYYY');

      return `${startDate}-${endDate}`;
    },
    progressText(): string {
      const count = this.progress.count > 0 ? ` (${this.progress.count})` : '';
      return `${this.progress.percent}%${count}`;
    },
    isFinished(): boolean {
      const {
        dateEnd,
      } = this.stage;
      const endDate = this.$dayjs.utc(dateEnd);

      return endDate.isSameOrBefore(this.$dayjs());
    },
    isDisabled(): boolean {
      return this.stage?.type === SurveyStageType.NEXT;
    },
  },
});
