
import Vue, { PropType } from 'vue';
import { AUDIENCE_AMOUNT_DATA, AudienceAmountData, AudienceAmountAliases } from '@/constants/audienceAmountStatuses';

const EXPERT_LIMIT_KEY = 'expertLimit';

export default Vue.extend({
  name: 'StatisticsAudienceChip',

  props: {
    amountAlias: {
      type: String as PropType<AudienceAmountAliases | typeof EXPERT_LIMIT_KEY>,
      required: true,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    expertLimit: {
      type: Number,
      default: 5,
    },
    amount: {
      type: Number,
      default: 0,
    },
  },

  computed: {
    statusData(): AudienceAmountData | null {
      return AUDIENCE_AMOUNT_DATA[this.amountAlias as AudienceAmountAliases] || null;
    },
    statusText(): string {
      const t18key = `surveyCompletionStatistics.userStatistics.choosingExperts.${this.amountAlias}`;
      // eslint-disable-next-line @intlify/vue-i18n/no-dynamic-keys
      return this.$tc(t18key, this.expertLimit) as string;
    },
    isVisible(): boolean {
      return this.amountAlias !== EXPERT_LIMIT_KEY;
    },
  },
});
