export enum AudienceAmountAliases {
  LESS = 'less',
  MORE = 'more',
}
export interface AudienceAmountData {
  icon?: string;
  color?: string;
  rateActionName: string;
}

export const AUDIENCE_AMOUNT_DATA: Record<AudienceAmountAliases, AudienceAmountData> = {
  [AudienceAmountAliases.LESS]: {
    icon: 'fa fa-times-circle',
    color: 'tt-light-orange',
    rateActionName: 'rateAnyway',
  },
  [AudienceAmountAliases.MORE]: {
    icon: 'fa fa-check-circle',
    color: 'tt-light-green vibrant',
    rateActionName: 'changeRating',
  },
};
